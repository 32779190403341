@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tower-of-hanoi {
  text-align: center;
  margin-top: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.towers {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 200px;
}

.tower {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.disk {
  background-color: #3498db;
  height: 20px;
  margin-bottom: 5px;
}

.moves {
  margin-top: 20px;
}

.game-over {
  color: green;
  margin-top: 10px;
}

.reset-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.reset-button:hover {
  background-color: #2980b9;
}
